import { BaseURLwithEndPoint } from "./config";
// import axios from "axios";
import { axiosInstance } from "../config/axios";

let base = BaseURLwithEndPoint();
const endpoint = "/communitylist/";
const handlerEnabled = true;

export const getAllCurations = async (row, page = 1,filters={},order_by=null) => {
	try {
		const res = await axiosInstance.get(endpoint, {
			params: { page_size: row, page: page,order_by,...filters },
			handlerEnabled,
		});

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getAllUser = async (row, page = 1) => {
	try {
		const res = await axiosInstance.get("/user/", {
			params: { page_size: row, page: page },
			handlerEnabled,
		});
		
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		console.log("====================================");
		console.log(e);
		console.log("====================================");
		let data = {
			success: false,
		};
		return data;
	}
};
export const getAllUser_lite = async (row, page = 1) => {
	try {
		const res = await axiosInstance.get("/get_all_user/", {
			params: { page_size: row, page: page },
			handlerEnabled,
		});
		
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		console.log("====================================");
		console.log(e);
		console.log("====================================");
		let data = {
			success: false,
		};
		return data;
	}
};

export const Create = async (params) => {
	try {
		const res = await axiosInstance.post("/communitylist/", params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};
export const getCurationDetails = async (id) => {
	try {
		const res = await axiosInstance.get(`${endpoint}${id}/`);

		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};

		return data;
	}
};

export const setActiveInactiveCuration = async (data) => {
	const res = await axiosInstance.put(`${endpoint}${data.id}/`, data);
	return res.data;
};

export const UpdateCuration = async (params, id) => {
	try {
		const res = await axiosInstance.put(`${endpoint}${id}/`, params);
		console.log("====================================");
		console.log(res);
		console.log("====================================");
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getAllActivePlayoffs = async () => {
	try {
		const res = await axiosInstance.get("activeplayoff/");
		console.log(res);
		const { data } = res;
		data["success"] = true;
		return res.data;
	} catch (e) {
		let data = {
			success: false,
		};
		return data;
	}
};

export const getRatingsByList= async (id,page=1)=>{
	const res = await axiosInstance.get(`list/${id}/ratings`,{params: { page_size: 10, page: page },});
	return res
}