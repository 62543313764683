import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";

import {
	Box,
	Button,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";

import {
	checkProductExistAPI,
	Create,
	getAllProduct,
	getProductPreview,
	Update,
} from "../../apis/Product";
import { ProductCard } from "./ProductCard";
import validator from "validator";

export const AddProductDrawer = ({
	setEditOpen,
	products,
	setRows,
	fromGroup = false,
	product,
	setProduct,
	page,
	rowsCount,
	selectedCategory=null
}) => {
	const [selectedSeller, setSelectedSeller] = useState("");

	

	const {
		register,
		handleSubmit,
		control,
		setValue,
		trigger,
		reset,
		formState: { errors },
	} = useForm();

	const [url, setUrl] = useState(product.url);
	const [productPreview, setProductPreview] = useState(null);
	const [existingProduct, setExistingProduct] = useState(null);
	
	const [fileName, setFileName] = useState("");
	const [croppedImage, setCroppedImage] = useState("");
	const [category, setCategory] = useState(product.product_category);
	const [showCategory, setShowCategory] = useState(false);
	const [affiliated_url, setAffiliated_url] = useState('');


	useEffect(() => {
		
		setProductPreview(product)
		setExistingProduct(product)
	}, []);
	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	
	const handleURL = async (e) => {
		if(url === e.target.value){
			return
		}
		setUrl(e.target.value);
		setValue("url", e.target.value);
		setProductPreview(null);

		const isValid = await trigger("url");
		if (isValid) {
			let result = await checkProductExistAPI(e.target.value);
			const data = await getProductPreview(e.target.value);
			reset();
			
			setProductPreview(data.data);
			setAffiliated_url(data?.affiliated_details[0]?.link)
			setExistingProduct(null);
			setSelectedSeller("");
			if (result.product_exist) {
				setExistingProduct(result.product);
				setCategory(selectedCategory?null:result.product.product_category)
			}
		} else {
			// setProductPreview(null);
			console.log("====================================");
			console.log(errors);
			console.log("====================================");
		}
	};
	const onSubmit = async (data) => {
		console.log(data);
		if (data.secondary_color==null){
			delete data.secondary_color
		}
		

		if(category==null){
			notify("category not selected")
			setShowCategory(true)
			return 
		}
		let formData = new FormData();
		data.product_category=category.id

		for (let i in data) {
			if (i === "file") {
				if(data[i].length){
					let blob = await fetch(croppedImage).then(r => r.blob());
					formData.append('file', blob, fileName);
				}
				
				
			}
			else formData.append(i, data[i]);
		}
		
		console.log(productPreview.out_of_stock);
		if (productPreview.out_of_stock !== undefined) {
			formData.append("out_of_stock", productPreview.out_of_stock);
		}
		
		
		
		if (existingProduct) {
			console.log(formData)
			let res = await Update(existingProduct.id, formData);
			console.log("yes");
			if (res.success) {
				let newBrands = await getAllProduct(rowsCount, page+1);
				setRows(newBrands.results);
			// 	setProducts([...products, res]);
				setEditOpen(false);
			}
		} 

		// form= new FormData()
	};
	
	
	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="h6"
							gutterBottom
						>{`Get Product`}</Typography>
						<Button
							disabled={productPreview == null}
							type="submit"
							color="primary"
							variant="contained"
						>
							{"Update"}
						</Button>
					</Box>

					<Box my={3}>
						<TextField
							fullWidth
							label="Paste or enter URL"
							variant="outlined"
							value={url}
							error={Boolean(errors.url)}
							helperText={errors.url?.message}
							{...register("url", {
								required: "Please enter url",
								
								validate: {
									// checkUrl: async (v) =>
									// 	(await checkProductExist(v)) ||
									// 	"Product already Exist",
										isURL: (value) =>
												validator.isURL(value) ||
												"Please enter a valid url",
										
								},
							})}
							onChange={handleURL}
						/>
					</Box>

					{productPreview && (
						<Box my={3}>
							<ProductCard
								product={productPreview}
								affiliated_url={affiliated_url}
								pre
								setFileName={setFileName}
								croppedImage={croppedImage}
								setCroppedImage={setCroppedImage}
								errors={errors}
								register={register}
								control={control}
								selectedSeller={selectedSeller}
								setSelectedSeller={setSelectedSeller}
								setValue={setValue}
								existingProduct={existingProduct}
								category={category} 
								setCategory={setCategory}
								showCategory={showCategory}
								setShowCategory={setShowCategory}
								selectedCategory={selectedCategory}
								edit={true}
								// primaryColor={primaryColor}
								// setPrimaryColor={setPrimaryColor}
								// secondaryColor={secondaryColor}
								// setSecondaryColor= {setSecondaryColor}
							/>
						</Box>
					)}

					<Box my={3}>
						{/* <Autocomplete
						multiple
						value={tags}
						onChange={(_, v) => {
							setTags(v);
						}}
						options={prodTags}
						getOptionLabel={(option) => option["tag_name"]}
						style={{ width: '100%' }}
						renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" />}
					/> */}
					</Box>
				</form>
			</div>
			
		</div>
	);
};
