import {  EditOutlined } from "@mui/icons-material";
import {
	Avatar,
	Checkbox,
	Drawer,
	FormControl,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Switch,
	Typography,
} from "@mui/material";
import { Link } from "@mui/material";

import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { getAllProduct } from "../../apis/Product";
import { getAllSeller } from "../../apis/Seller";
import { getAllBrand } from "../../apis/Brand";
import { AddProductDrawer } from "./AddProductDrawer";
import moment from "moment";

export default function DataTable({rows}) {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(0);
	const [filters, setFilters] = useState({});
	const [sortingValues, setSortingValues] = useState("-created_at");
	const [count, setCount] = useState(0);
	const [seller, setSeller] = useState([]);
	const [category, setCategory] = useState([]);
	const [rowsCount, setRowsCount] = useState(10);
	const [product, setProduct] = useState({});
	const [brand, setBrand] = useState([]);
	const [editOpen, setEditOpen] = useState(false);
	useEffect(() => {
		console.log('====================================');
		console.log(sortingValues);
		console.log('====================================');
		getAllProduct(rowsCount, page + 1, filters, sortingValues).then(
			(res) => {
				setData(res.results);
				setCount(res.count);
			}
		);
	}, [page, rowsCount, filters, sortingValues, rows]);
	useEffect(() => {
		getAllSeller().then((res) => {
			setSeller(res.results);
		});

		getAllBrand().then((res) => {
			setBrand(res.results);
		});
	}, []);
	const columns = [
		{
			name: "id",
			label: "Id",
			options: {
				filter: false,
				sort: true,
				setCellProps: () => ({ sx: { textAlign: "right" } }),
				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", tableMeta);
				// 	return tableMeta.rowIndex + 1;
				// },
			},
		},
		{
			name: "name",
			label: "Name",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Link
							target="_blank"
							href={data[tableMeta.rowIndex].url}
						>
							{value}
						</Link>
					);
				},
			},
		},
		{
			name: "product_category",
			label: "Category",
			options: {
				filter: false,
				sort: true,

				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", value);
				// 	return (
				// 		<Typography variant="p" ml={1}>
				// 			{value.name}
				// 		</Typography>
				// 	);
				// },
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return <Typography variant="p">{value.title}</Typography>;
				},
			},
		},
		{
			name: "brand",
			label: "Brand",
			options: {
				filter: true,
				sort: true,
				filterType: "custom",
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						// console.log("update");
						// console.log(filterList, filterPos, index);
						return filterList;
					},
				},
				filterOptions: {
					logic: (playoff, filters, row) => {
						if (filters.length) return !filters.includes(playoff);
						return false;
					},
					display: (filterList, onChange, index, column) => {
						return (
							<FormControl fullWidth variant="standard">
								<InputLabel>Brand</InputLabel>
								<Select
									multiple
									value={filterList[index]}
									renderValue={(selected) =>
										selected.join(", ")
									}
									onChange={(event) => {
										filterList[index] = event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
								>
									{brand.map((owner) => (
										<MenuItem
											key={owner.id}
											value={owner.name}
										>
											<Checkbox
												checked={
													filterList[index].indexOf(
														owner.name
													) > -1
												}
											/>
											<ListItemText
												primary={owner.name}
											/>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return <Typography variant="p">{value.name}</Typography>;
				},
			},
		},
		{
			name: "seller",
			label: "Supplier",
			options: {
				filter: true,
				sort: true,
				display: "true",
				filterType: "custom",
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						// console.log("update");
						// console.log(filterList, filterPos, index);
						return filterList;
					},
				},
				filterOptions: {
					logic: (playoff, filters, row) => {
						if (filters.length) return !filters.includes(playoff);
						return false;
					},
					display: (filterList, onChange, index, column) => {
						return (
							<FormControl fullWidth variant="standard">
								<InputLabel>Seller</InputLabel>
								<Select
									multiple
									value={filterList[index]}
									renderValue={(selected) =>
										selected.join(", ")
									}
									onChange={(event) => {
										filterList[index] = event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
								>
									{seller.map((name) => (
										<MenuItem
											key={name.id}
											value={name.name}
										>
											<Checkbox
												checked={
													filterList[index].indexOf(
														name.name
													) > -1
												}
											/>
											<ListItemText primary={name.name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						);
					},
				},
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					return <Typography variant="p">{value.name}</Typography>;
				},
			},
		},
		{
			name: "original_price",
			label: "Price",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);
					let row = data[tableMeta.rowIndex];

					return (
						<>
							{row.discount_price != 0 ? (
								<>
									<Typography
										sx={{
											px: 1,
											color: "#d32f2f",
											textDecorationLine: "line-through",
											fontWeight: "bold",
										}}
										variant="overline"
										display="inline"
									>
										{value}
									</Typography>
									<Typography
										sx={{
											fontWeight: "bold",
										}}
										display="inline"
									>
										{row.discount_price}
									</Typography>
								</>
							) : (
								<Typography
									sx={{
										fontWeight: "bold",
									}}
									display="inline"
								>
									{value}
								</Typography>
							)}
						</>
					);
				},
			},
		},
		{
			name: "image",
			label: "Preview",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					// console.log("yes", value);

					return (
						<Avatar
							alt="List Logo"
							variant="square"
							sx={{
								width: 50,
								height: 67,
								objectFit: "fill",

								margin: 5,
								borderRadius: 1,
							}}
							src={value?.file}
						/>
					);
				},
			},
		},
		{
			name: "out_of_stock",
			label: "Out of Stock",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<Switch
							checked={value}
							onChange={(event) => {
								event.preventDefault();
							}}
							inputProps={{
								"aria-label": "controlled",
							}}
						/>
					);
				},
			},
		},

		{
			name: "created_at",
			label: "Created At",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return moment(value).format("MMM DD YYYY hh:mm");
				},
			},
		},
		{
			name: "updated_at",
			label: "Update At",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return new Date(value).toString().substring(4, 24);
				},
			},
		},
		{
			name: "tag",
			label: "Tags",
			options: {
				filter: false,
				sort: true,

				// customBodyRender: (value, tableMeta, updateValue) => {
				// 	// console.log("yes", value);
				// 	return (
				// 		<Typography variant="p" ml={1}>
				// 			{value.name}
				// 		</Typography>
				// 	);
				// },
				customBodyRender: (value, tableMeta, updateValue) => {
					const groupedTags = value.reduce((group, tag) => {
						const { tag_type } = tag;
						group[tag_type] = group[tag_type] ?? [];
						group[tag_type].push(tag.name);
						return group;
					  }, {});
					
					  // Creating HTML content
					  let htmlContent = '<div>';
					  
					  for (const [tagType, tagNames] of Object.entries(groupedTags)) {
						htmlContent += `<strong>${tagType.toUpperCase()}</strong><ul>`;
						tagNames.forEach(tagName => {
						  htmlContent += `<li>${tagName}</li>`;
						});
						htmlContent += '</ul>';
					  }
					  
					  htmlContent += '</div>';
					
					  // Returning HTML as the content of the cell
					  return (
						<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
					  );
					  
					  
					// return <Typography variant="p">{value.title}</Typography>;
				},
			},
		},

		{
			name: "",
			label: "Action",
			options: {
				filter: false,
				sort: true,
				viewColumns: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<IconButton
							color="primary"
							onClick={() => {
								setProduct(data[tableMeta.rowIndex]);
								setCategory(product.category);
								setEditOpen(true);
							}}
						>
							<EditOutlined />
						</IconButton>
					);
				},
			},
		},
	];
	const options = {
		sortOrder: {
        name: 'created_at',
        direction: 'desc'
      },

		jumpToPage: true,
		selectableRows: "none",
		download: false,
		filterType: "multiselect",
		serverSide: true,
		print: false,
		count: count,
		page: page,
		rowsPerPage: rowsCount,
		rowsPerPageOptions: [10, 25, 100],
		onChangePage(currentPage) {
			setPage(currentPage);
		},
		onChangeRowsPerPage(numberOfRows) {
			setRowsCount(numberOfRows);
		},
		onSearchChange: (searchText) => {
			setFilters({ ...filters, title: searchText });
		},
		onColumnSortChange: (changedColumn, direction) => {
			setSortingValues(
				direction === "desc" ? `-${changedColumn}` : `${changedColumn}`
			);
			// setPage(0)
			// setSortFields(temp);
		},
		onFilterChange: (columnChanged, filterList, type, index) => {
			setPage(0);
			let value = filterList[index];
			let filterdata = {};
			let newar = [];
			if (columnChanged === "brand") {
				newar = brand
					.filter(({ name }) => value.includes(name))
					.map((play) => play.id);
				console.log("====================================");
				console.log(newar, value);
				console.log("====================================");
				filterdata[columnChanged] = newar;
				setFilters({ ...filters, ...filterdata });
			} else if (columnChanged === "seller") {
				newar = seller
					.filter(({ name }) => value.includes(name))
					.map((play) => play.id);

				filterdata[columnChanged] = newar;
				setFilters({ ...filters, ...filterdata });
			}
		},

		// onTableChange: (action, tableState) => {
		// 	switch (action) {
		// 		case "sort":
		// 			sortTable(tableState.page, tableState.sortOrder);
		// 			break;
		// 		default:
		// 			console.log("action not handled.");
		// 	}
		// },
	};

	return (
		<>
			<MUIDataTable
				title={"Products List"}
				data={data}
				columns={columns}
				options={options}
			/>
			<Drawer
				anchor="right"
				open={editOpen}
				onClose={() => setEditOpen(false)}
			>
				<AddProductDrawer
					page={page}
					rowsCount={rowsCount}
					selectedCategory={category}
					title={"Edit"}
					setEditOpen={setEditOpen}
					product={product}
					setRows={setData}
				/>
			</Drawer>
			{/* <TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							{[
								"S No.",
								"Name",
								"Category",
								"Brand",
								"Supplier",
								"Price",
								"Preview",
								"Out of Stock",
								"Created at",
								"Updated at",
								"",
							].map((header, i) => (
								<StyledTableCell key={`header-${i}`}>
									{header}
								</StyledTableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, i) => (
							<TableRow key={i + 1}>
								<StyledTableCell> {page * rowsCount + i + 1}</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 120, maxWidth: 150 }}
								>
									<Link target="_blank" href={row["url"]}>
										{row["name"]}
									</Link>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 120 }}
								>
									{row.product_category?.master &&
										`${row.product_category?.master.name} - `}
									{row.product_category?.name}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 120 }}
								>
									{row.brand?.name}
								</StyledTableCell>
								<StyledTableCell>
									{row.seller?.name}
								</StyledTableCell>
								<StyledTableCell>
									<Typography
										sx={{
											px: 1,
											color: "#d32f2f",
											textDecorationLine: "line-through",
											fontWeight: "bold",
										}}
										variant="overline"
										display="inline"
									>
										{row.original_price}
									</Typography>
									<Typography
										sx={{
											fontWeight: "bold",
										}}
										display="inline"
									>
										{row.discount_price}
									</Typography>
								</StyledTableCell>
								<StyledTableCell>
									<img
										src={row.image.file}
										alt=""
										width="50px"
										style={{
											margin: 5,
											borderRadius: 5,
										}}
									/>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 100 }}
								>
									<Switch
										checked={row.out_of_stock}
										color="primary"
									/>
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 100 }}
								>
									{new Date(row["created_at"])
										.toString()
										.substring(4, 24)}
								</StyledTableCell>
								<StyledTableCell
									style={{ minWidth: 100, maxWidth: 100 }}
								>
									{new Date(row["updated_at"])
										.toString()
										.substring(4, 24)}
								</StyledTableCell>
								<StyledTableCell>
									<IconButton
										color="primary"
										onClick={() => handleEdit(i)}
									>
										<EditOutlined />
									</IconButton>
									{/* <IconButton
										color="secondary"
										onClick={() =>
											handleDelete(page * rowsCount + i)
										}
									>
										<DeleteOutline />
									</IconButton> 
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				sx={{
					"& p": {
						m: 0,
					}}}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={count}
				rowsPerPage={rowsCount}
				page={page}
				onPageChange={handlePage}
				onRowsPerPageChange={(e) => handleRowsCount(e.target.value)}
			/>
			{loading && (
				<Box display="flex" width="100%" justifyContent="center" p={3}>
					<CircularProgress />
				</Box>
			)} */}
		</>
	);
}
