import React, { useState } from "react";

import { CheckBox, Close } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import {
	
	getProductPreview,
} from "../../apis/Api";
import { ProductCard } from "./ProductCard";
import { ToastContainer, toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { checkProductExistAPI, Create, getAllProduct } from "../../apis/Product";
import validator from "validator";
import Loader from "../Loader/Loader";


export const EditProductDrawer = ({
	title,
	setEditOpen,
	product,
	users,
	setRows,
	tags: prodTags,
	page,
	rowsCount,
}) => {
	const [selectedSeller, setSelectedSeller] = useState("");
	const {
		register,
		handleSubmit,
		control,
		setValue,
		trigger,
		formState: { errors },
	} = useForm();

	const [url, setUrl] = React.useState(product["product_url"] ?? "");
	const [productPreview, setProductPreview] = React.useState(null);
	const [fileName, setFileName] = useState("");
	const [croppedImage, setCroppedImage] = useState("");
	const [category, setCategory] = useState(null);
	const [showCategory, setShowCategory] = useState(false);
	const [loading, setLoading] = useState(false);
	const [affiliated_url, setAffiliated_url] = useState('');

	const notify = (m) =>
		toast.error(m, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const checkProductExist = async (url) => {
		let result = await checkProductExistAPI(url);
		console.log("====================================");
		console.log(result);
		console.log("====================================");
		return !result.product_exist;
	};
	const handleURL = async (e) => {
		setUrl(e.target.value);
		setValue("url", e.target.value);
		setProductPreview(null);

		const isValid = await trigger("url");
		if (isValid) {
			const data = await getProductPreview(e.target.value);
			setProductPreview(data.data);			
			setAffiliated_url(data?.affiliated_details[0]?.link)
		} else {
			// setProductPreview(null);
			console.log("====================================");
			console.log(errors);
			console.log("====================================");
		}
	};
	const onSubmit = async (data) => {
		console.log("#########################")
		setLoading(true);
		if(!data.image){
			delete data.image
		}else if(data.image.startsWith('//')){
			data.image=`https:${data.image}`
		}
		console.log(data);
		if (!data.seller && selectedSeller) {
			data.seller = selectedSeller;
		}
		console.log(data);
		if(category==null){
			notify("category not selected")
			setShowCategory(true)
			return 
		}
		let formData = new FormData();
		data.product_category=category.id
		// formData.append('product_category',category.id)
		for (let i in data) {
			if (i === "file" && data[i].length) {

				let blob = await fetch(croppedImage).then(r => r.blob());
				formData.append('file', blob, fileName);
			} else formData.append(i, data[i]);
		}
		console.log(productPreview.out_of_stock);
		if (productPreview.out_of_stock !== undefined) {
			formData.append("out_of_stock", productPreview.out_of_stock);
		}
		formData.append("name", data.name);
		
		let res = await Create(formData);
		if (res.success) {
			
			let newBrands = await getAllProduct(rowsCount, page+1);
				setRows(newBrands.results);
				setEditOpen(false);
		}
		setEditOpen(false);
		
		setLoading(false);
		// form= new FormData()
	};

	return (
		<div
			style={{
				width: 600,
				padding: 5,
				height: "100vh",
				overflowY: "scroll",
			}}
		>
			{loading && <Loader />}
			<IconButton
				style={{ position: "fixed" }}
				onClick={() => setEditOpen(false)}
			>
				<Close />
			</IconButton>
			<ToastContainer />
			<div style={{ padding: "45px 55px 0px" }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box display="flex" justifyContent="space-between">
						<Typography
							variant="h6"
							gutterBottom
						>{`${title} Product`}</Typography>
						<Button
							disabled={productPreview == null}
							type="submit"
							color="primary"
							variant="contained"
						>
							{title === "Edit" ? "Update" : "Save"}
						</Button>
					</Box>

					{!product["_id"] && (
						<Box my={3}>
							<TextField
								fullWidth
								label="Paste or enter URL"
								variant="outlined"
								value={url}
								error={Boolean(errors.url)}
								helperText={errors.url?.message}
								{...register("url", {
									required: "Please enter url",
									
									validate: {
										checkUrl: async (v) =>
											(await checkProductExist(v)) ||
											"Product already Exist",
											isURL: (value) =>
													validator.isURL(value) ||
													"Please enter a valid url",
											
									},
								})}
								onChange={handleURL}
							/>
						</Box>
					)}

					{productPreview && (
						<Box my={3}>
							<ProductCard
								product={productPreview}
								pre
								affiliated_url={affiliated_url}
								errors={errors}
								register={register}
								control={control}
								selectedSeller={selectedSeller}
								setSelectedSeller={setSelectedSeller}
								setValue={setValue}
								setFileName={setFileName}
								croppedImage={croppedImage}
								setCroppedImage={setCroppedImage}
								category={category} 
								setCategory={setCategory}
								showCategory={showCategory}
								setShowCategory={setShowCategory}
							/>
						</Box>
					)}

					
				</form>
			</div>
		</div>
	);
};
